<template>
  <div>
    <div class="fix-head head">
      <span>首页楼层部署可自定义排序，样式最佳排序顺序可参考 </span>
      <span class="demo cursor" @click="toDemo"> 楼层排序示例</span>
    </div>
    <div class="content">
      <div class="item">
        <div class="mb-12 flex">
          <span class="label">楼层名称：</span>
          <el-input v-model="edit.activity_name" style="width: 435px;" placeholder="建议四字，最多五字" :maxlength="5"></el-input>
        </div>
        <div class="tip">
          <span class="tips">保证界面样式规范，楼层名称建议统一字数，</span>
          <span class="demo cursor" @click="toDemo">查看示例</span>
        </div>
        <div class="flex" style="margin-top: 24px;">
          <span class="label">起始日期：</span>
          <el-date-picker
            v-model="edit.startend"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </div>
      </div>
      <div class="item">
        <div class="mb-24 flex">
          <span class="label">楼层类型：</span>
          <el-radio-group v-model="edit.jump_type" @change="radioChange" style="display: none;">
            <el-radio :label="2">商品专题</el-radio>
            <el-radio :label="3">品牌</el-radio>
          </el-radio-group>
        </div>
        <template v-if="edit.jump_type === 2">
          <div class="flex">
            <div class="style">
              <div class="style-item">
                <div class="style-head">
                  <el-radio v-model="edit.style_type" :label="1">选择样式一</el-radio>
                </div>
                <img src="https://ddimage.topboom0912.com/admin_static_img/demo/floor_one.png" alt="style" width="375px" style="margin-bottom: 148px;">
              </div>
              <span class="tips">提示：该样式统一部署</span>
              <span class="demo">白底</span>
              <span class="tips">商品图</span>
            </div>
            <div class="style">
              <div class="style-item">
                <div class="style-head">
                  <el-radio v-model="edit.style_type" :label="2">选择样式二</el-radio>
                </div>
                <img src="https://ddimage.topboom0912.com/admin_static_img/demo/floor_two.png" alt="style" width="375px">
              </div>
              <span class="tips">提示：该样式建议部署</span>
              <span class="demo">专题Banner</span>
              <span class="tips">图</span>
            </div>
          </div>
          <div class="flex" style="margin-top: 65px;">
            <div class="style">
              <div class="style-item">
                <div class="style-head">
                  <el-radio v-model="edit.style_type" :label="3">选择样式三</el-radio>
                </div>
                <img src="https://ddimage.topboom0912.com/admin_static_img/demo/floor_three.png" alt="style" width="375px">
              </div>
              <span class="tips">提示：该样式统一部署</span>
              <span class="demo">白底</span>
              <span class="tips">商品图</span>
            </div>
            <div class="style" style="display: none;">
              <div class="style-item">
                <div class="style-head">
                  <el-radio v-model="edit.style_type" :label="4">选择样式四</el-radio>
                </div>
                <img src="https://image.topboom0912.com/admin_static_img/demo/floor_five.png" alt="style" width="375px">
              </div>
            </div>
          </div>
        </template>
        <div v-else class="flex">
          <div class="style">
            <div class="style-item">
              <div class="style-head">
                <el-radio v-model="edit.style_type" :label="0">选择样式一</el-radio>
              </div>
              <img src="https://image.topboom0912.com/admin_static_img/demo/floor_three.png" alt="style" width="375px">
            </div>
            <span class="tips">提示：品牌样式会推荐前3张商品图，适合部署</span>
            <span class="demo">白底</span>
            <span class="tips">商品图</span>
          </div>
        </div>
      </div>
      <div class="item link">
        <!-- <div class="title">关联商品专题</div> -->
        <div class="mb-12 flex">
          <span class="label">{{edit.jump_type !== 3 ? '关联专题：' : '关联品牌：'}}</span>
          <el-select v-model="edit.external_params">
            <template v-if="edit.jump_type !== 3">
              <el-option
                v-for="item in themaOptions"
                :key="item.id"
                :label="item.themeName"
                :value="item.id"
              />
            </template>
            <template v-else>
              <el-option
                :label="item.brandName"
                :value="item.id"
                v-for="item in brandOptions"
                :key="item.id"
              />
            </template>
          </el-select>
        </div>
        <div class="mt-32 mb-12 flex">
          <span class="label">楼层排序：</span>
          <el-input v-model="edit.sort" style="width: 435px;" placeholder="请输入排序号"></el-input>
        </div>
        <div class="tip">
          <span class="tips">排序号越小，排序位置越前，首图可增加曝光机会 </span>
          <span class="demo cursor" @click="toDemo"> 楼层排序示例</span>
        </div>
        <div class="mt-32 banner flex" v-if="edit.jump_type === 3">
          <span class="label">品牌banner：</span>
          <div class="upload">
            <Upload id="banner" src="activity" @success="onUpload">
              <div class="logo mb-12" v-if="edit.imgurl">
                <img :src="edit.imgurl" alt="img" width="100%">
              </div>
              <div class="upload-box mb-12 flex-center" v-else>
                <i class="el-icon-plus"></i>
                <span class="text">点击上传</span>
              </div>
            </Upload>
            <span class="tips">建议图片尺寸1125x705像素，且大小 &lt;=300KB，支持jpg、png格式</span>
          </div>
        </div>
        <div class="mt-32 flex">
          <span class="label">部署状态：</span>
          <el-radio-group v-model="edit.status">
            <el-radio :label="1">上架</el-radio>
            <el-radio :label="0">下架</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="foot">
        <el-button type="primary" @click="onConfirm">确定</el-button>
        <el-button @click="onCancel">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import Upload from '@/components/Upload'
export default {
  name: 'IndexFlowAdd',
  components: {
    Upload
  },
  data() {
    return {
      id: null,
      detail: null,
      edit: {
        activity_name: '',
        jump_type: 2,
        style_type: 1,
        sort: '',
        status: 1,
        startend: null,
        external_params: '',
        img: {
          w: 100,
          h: 100,
          url: ''
        },
        imgurl: ''
      },
      themaOptions: []
    }
  },
  computed: mapState([
    'brandOptions'
  ]),
  async mounted() {
    this.id = this.$route.query.id * 1 || null
    this.themaOptions = await this.getThemaList()
    if (this.id) {
      this.detail = await this.getDetail()
    }
  },
  methods: {
    getThemaList() {
      const url = 'admin/theme/list?page_no=0&page_size=99'
      return new Promise(resolve => {
        this.$https.get(url).then(data => {
          resolve(data.list)
        })
      })
    },
    getDetail() {
      const url = 'admin/activity/detail?id=' + this.id
      return new Promise(resolve => {
        this.$https.get(url).then(data => {
          if (!data) return
          this.edit.jump_type = data.jump_type
          this.edit.activity_name = data.activity_name
          this.edit.startend = [data.start_time, data.end_time]
          this.edit.status = data.status
          this.edit.style_type = data.style_type
          this.edit.external_params = data.external_params * 1
          this.edit.imgurl = this.$img(data.img)
          this.edit.img = JSON.parse(data.img)
          this.edit.sort = data.sort
          resolve(data)
        })
      })
    },
    onUpload(url) {
      this.edit.imgurl = url
    },
    radioChange(val) {
      this.edit.external_params = ''
      if (val === 2) {
        this.edit.style_type = 1
      } else {
        this.edit.style_type = 0
      }
    },
    toDemo() {
      this.$alert('<img src="https://ddimage.topboom0912.com/admin_static_img/demo/index_topbanner.png" alt="demo" width="298px">', '', {
        dangerouslyUseHTMLString: true,
        customClass: 'tb-demo-dialog'
      })
    },
    onConfirm () {
      const {activity_name, jump_type, style_type, sort, startend, status, img, imgurl, external_params} = this.edit
      if (!activity_name || !jump_type) {
        this.$message.error('请完善活动信息')
        return
      }
      if (style_type === 0 && !imgurl) {
        this.$message.error('请上传品牌banner')
        return
      }
      if (!startend) {
        this.$message.error('请选择日期')
        return
      }
      const url = 'admin/activity/edit'
      let params = {
        position_type: 4,
        activity_name,
        start_time: startend[0],
        end_time: startend[1],
        status,
        sort,
        jump_type,
        style_type,
        external_params
      }
      if (style_type === 0 && img.url !== imgurl) {
        params.img = img
        params.img.url = imgurl
        params.img = JSON.stringify(img)
      }
      if (this.detail) params = Object.assign({}, this.detail, params)
      this.$https.post(url, params).then(data => {
        if (data) {
          this.$message.success(this.id ? '编辑成功' : '添加成功')
          this.edit.activity_name = ''
          this.edit.jump_type = 2
          this.edit.style_type = 1
          this.edit.sort = ''
          this.edit.startend = null
          this.edit.status = 1
          this.edit.external_params = ''
          this.$router.go(-1)
        }
      })
    },
    onCancel() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.tips {
  color: rgba(0, 0, 0, 0.45);
}
.demo {
  color: #1890FF;
}
.head {
  padding-bottom: 16px;
  color: rgba(0, 0, 0, 0.65);
}
.item {
  padding: 32px 125px;
  border-bottom: 1px solid #e8e8e8;
  box-sizing: border-box;
  &:first-child {
    padding-top: 0;
  }
  .flex {
    align-items: center;
    .label {
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .mb-12 {
    margin-bottom: 12px;
  }
  .mb-24 {
    margin-bottom: 24px;
  }
  .mt-32 {
    margin-top: 32px;
  }
  .banner {
    align-items: inherit;
  }
  .style+.style {
    margin-left: 72px;
  }
  .style-item{
    width: 375px;
    box-sizing: border-box;
    border: 1px solid #E8E8E8;
    overflow: hidden;
    font-size: 0;
    margin-bottom: 12px;
    .style-head {
      padding: 12px;
      color: rgba(0, 0, 0, 0.65);
      border-bottom: 1px solid #E8E8E8;
      box-sizing: border-box;
      font-size: 14px;
    }
  }
  .upload {
    display: flex;
    flex-direction: column;
    line-height: 1;
    &-box {
      width: 375px;
      height: 235px;
      flex-direction: column;
      border-radius: 4px;
      border: 1px dashed rgba(0, 0, 0, 0.15);
      cursor: pointer;
      .text {
        padding-top: 8px;
        color: rgba(0, 0, 0, 0.65);
      }
    }
    .logo {
      position: relative;
      width: 375px;
      height: 235px;
      overflow: hidden;
      cursor: pointer;
    }
  }
  .tip {
    padding-left: 70px;
  }
}
.link {
  border-bottom: 0;
  // .title {
  //   font-size: 16px;
  //   font-weight: 600;
  //   color: rgba(0, 0, 0, 0.85);
  //   padding-bottom: 24px;
  // }
}
.foot {
  text-align: center;
}
</style>
